<template>
  <MCarousel
    v-if="categories?.length"
    :items-per-slide="1"
    dots="out"
    loop
    :transition="150"
    class="m-homepage-category"
  >
    <div
      v-for="(group, i) in categories"
      :key="`group-${i}`"
      class="m-homepage-category__item"
    >
      <ServerRender
        is="PopularCategory"
        v-for="(category, idx) in group"
        :key="`group-${i}-${idx}`"
        :item="category"
        :is-first-screen="isFirstScreen"
        variant="fix"
        with-pixel-ratio
        with-webp
      />

      <div
        v-if="more && i === categories.length - 1"
        class="m-homepage-category__more"
        @click.prevent="openModal('search')"
      >
        {{ $t("other.see-all") }}
      </div>
    </div>
  </MCarousel>
</template>

<script setup lang="ts">
import { defineComponent } from 'vue'
import { useCategories } from '@/stores/categories'
import ServerRender from '@/components/helpers/ServerRender'
import MCarousel from '@/components/molecules/Carousel/MCarousel.vue'
import { useHydrationData } from '@/modules/nuxt-utils/runtime/hydration-data'

defineComponent({ name: 'MHomepageCategory' })

const props = defineProps({
  itemsPerGroup: {
    type: Number,
    default: 6
  },
  more: {
    type: Boolean,
    default: false
  },
  isFirstScreen: {
    type: Boolean,
    default: false
  }
})

const { openModal } = useModals()
const { getNavigationMenu } = useCategories()

const { data: categories } = await useHydrationData('home-categories', async () => {
  const navigationMenu = await getNavigationMenu()

  const sectionsList = navigationMenu?.popularSections?.sectionsList?.slice?.(0, 11) || []

  if (!sectionsList?.length) {
    return []
  }

  const items = sectionsList.map(el => ({
    ...el,
    image: {
      ...el.image,
      url: getUrlWithTrailingSlashes(`${el.image?.url}`, false)
    }
  }))

  const itemsPerGroup = props.itemsPerGroup > 0 ? props.itemsPerGroup : 1

  return [...Array(Math.ceil(items.length / itemsPerGroup)).keys()]
    .map(el => items.slice(el * itemsPerGroup, (el + 1) * itemsPerGroup))
}, {
  catch: () => undefined,
  lazy: true
})
</script>

<style lang="postcss">
.m-homepage-category {
  --carousel-item-width: 100%;

  &__item {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacer-3xs);
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }

  &__more {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5.5rem;
    border-radius: var(--border-radius-base);
    background-color: var(--color-neutral-200);
    color: var(--color-info-middle);
    cursor: pointer;

    @mixin text-sm-semibold;
  }

  & .popular-category,
  &__more {
    width: 100%;
  }

  & .image > img {
    mix-blend-mode: multiply;
  }

  @media (--screen-lg) {
    display: none !important;
  }

  @media (--screen-3xs) {
    &__item {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
